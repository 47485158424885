import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUser } from "./userAPI";
import { differenceInHours } from "date-fns";

const initialState = {
  userName: null,
  userId: null,
  isAdult: false,
  isLoggedIn: false,
  userActivity: [],
  dailyChores: [],
  remainingChores: [],
  hasLoaded: false,
  isLoading: false,
  hasError: false,
  error: null,
};

function getLocalUser() {
  const userName = JSON.parse(window.localStorage.getItem("userName")) || null;
  const userId = JSON.parse(window.localStorage.getItem("userId")) || null;
  return { userName, userId };
}

function setLocalUser(user) {
  window.localStorage.setItem("userName", JSON.stringify(user.userName));
  window.localStorage.setItem("userId", JSON.stringify(user.userId));
}

function clearLocalUser() {
  window.localStorage.removeItem("userName");
  window.localStorage.removeItem("userId");
}

export const fetchUser = createAsyncThunk("user/fetchUser", async (user) => {
  setLocalUser(user);
  const response = await getUser(user);
  // console.log(response);
  // The value we return becomes the `fulfilled` action payload
  return response.user;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    initialLoad: (state, action, dispatch) => {
      const { userName, userId } = getLocalUser();
      if (userName && userId) {
        state.userName = userName;
        state.userId = userId;
        state.isLoggedIn = true;
        fetchUser({ userName, userId });
      } else {
        console.info("No user found in local storage");
      }
    },
    setUser: (state, action) => {
      const { userName, userId } = action.payload;
      setLocalUser({ userName, userId });
      state.userName = userName;
      state.userId = userId;
    },
    logout: (state) => {
      clearLocalUser();
      state.userName = null;
      state.userId = null;
      state.isLoggedIn = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const remainingChores = getRemainingChores(
          action.payload.dailyChores,
          action.payload.userActivity
        );

        // console.log(remainingChores);
        state.hasLoaded = true;
        state.isLoggedIn = true;
        state.isLoading = false;
        state.userActivity = action.payload.userActivity;
        state.remainingChores = remainingChores;
        state.dailyChores = action.payload.dailyChores;
        state.isAdult = action.payload.isAdult;
      });
  },
});

export const { setUser, logout, initialLoad } = userSlice.actions;

export default userSlice.reducer;

function getRemainingChores(dailyChores, userActivity) {
  const doneToday = userActivity
    .filter((activity) => {
      const { lastCompletedDate } = activity;
      const today = new Date();
      if (differenceInHours(today, new Date(lastCompletedDate)) < 12) {
        return activity;
      }
      return false;
    })
    .map(({ name }) => name);

  const notDoneYet = dailyChores.filter((chore) => {
    if (!doneToday.includes(chore.name)) {
      return chore;
    }
    return false;
  });

  return notDoneYet;
}
