import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import Frame from "./features/Frame";

import "./App.css";

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Frame />
      </Router>
    </ChakraProvider>
  );
}

export default App;
