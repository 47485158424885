import React, { useEffect } from "react";
import Task from "./Task";

import {
  Box,
  List,
  ListItem,
  Spinner,
  Input,
  InputLeftAddon,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";

import { Search2Icon, CloseIcon } from "@chakra-ui/icons";

export default function ChoreList({
  data,
  hasLoaded,
  onChoreComplete,
  doOneOffChore,
}) {
  if (!hasLoaded) {
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />;
  }

  const [searchTerm, setSearchTerm] = React.useState("");

  const handleChange = (event) => setSearchTerm(event.target.value);
  const [tasks, setTasks] = React.useState(data);

  useEffect(() => {
    if (searchTerm) {
      const filteredTasks = data.filter((task) =>
        task.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setTasks(filteredTasks);
    } else {
      setTasks(data);
    }
  }, [searchTerm, data]);

  const OneOffLabel = () =>
    tasks.length > 0 ? <CloseIcon /> : <div>Did It!</div>;

  return (
    <Box>
      <InputGroup size="sm" marginBottom={1}>
        <InputLeftAddon>
          <Search2Icon />
        </InputLeftAddon>
        <Input value={searchTerm} onChange={handleChange} />
        <InputRightAddon
          onClick={() => {
            if (tasks.length > 0) {
              setSearchTerm("");
            } else {
              doOneOffChore({ name: searchTerm });
              setSearchTerm("");
            }
          }}
        >
          <OneOffLabel />
        </InputRightAddon>
      </InputGroup>

      <List spacing={2}>
        {tasks.map((chore, i) => (
          <ListItem key={`${i}-${chore.name}`}>
            <Task {...chore} onCheckboxChange={onChoreComplete} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
