export const getUsersActivity = async (userDetails) => {
  //fetch post userDetails to api/login and execute the callback function onUserUpdate
  const data = await fetch("/api/usersActivity", {
    //fetch post userDetails to api/login
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userDetails),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  return data;
};

export const deleteUserActivity = async (task) => {
  //fetch post userDetails to api/login and execute the callback function onUserUpdate
  const data = await fetch("/api/activity", {
    //fetch post userDetails to api/login
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(task),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  return data;
};
