import { configureStore } from "@reduxjs/toolkit";
import choresReducer from "../features/chores/choresSlice";
import userReducer from "../features/user/userSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";

export const store = configureStore({
  reducer: {
    chores: choresReducer,
    user: userReducer,
    dashboard: dashboardReducer,
  },
});
