//react form with userName and userKey

import React from "react";
import {
  FormControl,
  FormLabel,
  Box,
  Center,
  Button,
  Input,
} from "@chakra-ui/react";

//functional component form with two input fields and a submit button
const Login = ({ onSubmit }) => {
  //useState hook to set the state of the userName and userKey
  const [userDetails, setUserDetails] = React.useState({
    userName: "",
    userId: "",
  });

  const handleChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const loginUser = async () => {
    onSubmit(userDetails);
  };

  return (
    <Center>
      <Box>
        <FormControl id="userName" isRequired>
          <FormLabel>User Name</FormLabel>
          <Input
            name="userName"
            value={userDetails.userName}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl id="userId" isRequired>
          <FormLabel>User Key</FormLabel>
          <Input
            name="userId"
            value={userDetails.userId}
            onChange={handleChange}
          />
        </FormControl>

        <Button mt={4} colorScheme="teal" onClick={loginUser} type="submit">
          Submit
        </Button>
      </Box>
    </Center>
  );
};

export default Login;
