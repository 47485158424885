import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { TimeIcon, StarIcon, CheckIcon } from "@chakra-ui/icons";
import { startCase } from "lodash";

function Task({ name, points, onCheckboxChange }) {
  const [isChecked, setIsChecked] = React.useState(false);

  const nameIcon = (name) => {
    return name.includes("5 minute") ? (
      <TimeIcon color="gray.700" marginRight="6px" />
    ) : (
      <Box marginRight="6px"></Box>
    );
  };

  return (
    <Button
      leftIcon={<CheckIcon />}
      w="300px"
      size="xs"
      colorScheme={isChecked ? "pink" : "teal"}
      variant="outline"
      onClick={() => {
        setIsChecked(!isChecked);
        onCheckboxChange({ name, checked: true });
      }}
      disabled={isChecked}
    >
      {nameIcon(name)}
      {startCase(name).replace(/5 minute focus/gi, "")}

      {Array.from({ length: points }).map((_, i) => (
        <StarIcon
          ml={i === 0 ? 5 : 2}
          w={3}
          h={3}
          key={`${name}-${i}-star`}
          color="teal.400"
        />
      ))}
    </Button>
  );
}

export default Task;
