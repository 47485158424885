/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Wrap, WrapItem, Center, Button, Box } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, Link } from "react-router-dom";

import Login from "./Login";
import UserDashboard from "../UserDashboard";
import ChoreList from "../ChoreList";

import { differenceInMilliseconds } from "date-fns";
import { TIMEOUT_COUNTDOWN } from "../constants";

import { setUser, logout, fetchUser, initialLoad } from "../user/userSlice";
import {
  fetchUsersActivity,
  fetchUserActivityUpdate,
} from "../dashboard/dashboardSlice";
import {
  fetchChores,
  setChoreComplete,
  setOneOffChoreComplete,
} from "../chores/choresSlice";
import { useEffect, useState } from "react";

export default function Frame() {
  const { user, chores, dashboard } = useSelector((state) => state);
  const { isLoggedIn } = user;
  const [taskCompleted, setTaskCompleted] = useState(new Date());

  const [lastChore, setLastChore] = useState("");

  const { userName, userId } = user;

  const dispatch = useDispatch();
  useEffect(() => {
    // console.log("useEffect triggered");
    if (!isLoggedIn) {
      // console.log("isLoggedIn is false");
      dispatch(initialLoad());
    }
    if (isLoggedIn && !dashboard.isLoaded) {
      // console.log("getting activity");
      const { userName, userId } = user;
      dispatch(fetchUsersActivity({ userName, userId }));
      dispatch(fetchChores({ userName, userId }));
    }
  }, [dispatch, isLoggedIn, dashboard.isLoaded, user]);

  useEffect(() => {
    if (dashboard.hasLoaded) {
      dispatch(fetchUsersActivity({ userName, userId }));
      dispatch(fetchChores({ userName, userId }));
      dispatch(fetchUser({ userName, userId }));
    }
  }, [taskCompleted, dashboard.hasLoaded, userName, userId, dispatch]);

  const deleteChore = (chore) => {
    console.log(`deleting chore!`, chore);
    dispatch(fetchUserActivityUpdate(chore));
  };

  const updateChore = ({ name }) => {
    const diff = differenceInMilliseconds(new Date(), taskCompleted);
    console.log(`diff is ${diff}`);

    if (lastChore === name && diff < TIMEOUT_COUNTDOWN) {
      return null;
    }

    dispatch(
      setChoreComplete({
        ...user,
        choreName: name,
      })
    );
    setLastChore(name);
    setTaskCompleted(new Date());
  };

  const doOneOffChore = ({ name }) => {
    dispatch(
      setOneOffChoreComplete({
        ...user,
        choreName: name,
      })
    );
    setTaskCompleted(new Date());
  };

  return (
    <div
      css={css`
        height: 100vh;
        display: grid;
        grid-template-rows: auto 1fr;
        max-width: 100vw;
      `}
    >
      <div
        css={css`
          padding: 0 0;
          grid-row-start: 1;
          grid-row-end: 2;
        `}
      >
        <Center bg="teal.600" color="white">
          {user.isLoggedIn && (
            <Box p="2">
              <Wrap>
                <WrapItem>
                  <Link to="/">Home</Link>
                </WrapItem>
                <WrapItem>
                  <Link to="/chores">Chores</Link>
                </WrapItem>
                <WrapItem>
                  <Link
                    to="/dashboard"
                    onClick={() =>
                      dispatch(fetchUsersActivity({ userName, userId }))
                    }
                  >
                    Dashboard
                  </Link>
                </WrapItem>
                <WrapItem>
                  <Button
                    size="sm"
                    color="black"
                    onClick={() => dispatch(logout())}
                  >
                    Sign Out
                  </Button>
                </WrapItem>
              </Wrap>
            </Box>
          )}
        </Center>
      </div>
      <div
        css={css`
          padding: 1rem;
          overflow-y: scroll;
        `}
      >
        <Center>
          {!user.isLoggedIn && (
            <Login
              onSubmit={(details) => {
                dispatch(fetchUser(details));
                dispatch(setUser(details));
              }}
            />
          )}
          {user.isLoggedIn && (
            <Switch>
              <Route exact path="/">
                <ChoreList
                  hasLoaded={user.hasLoaded}
                  data={user.remainingChores}
                  onChoreComplete={updateChore}
                  doOneOffChore={doOneOffChore}
                />
              </Route>
              <Route path="/chores">
                <ChoreList
                  hasLoaded={chores.hasLoaded}
                  data={chores.sharedChores}
                  onChoreComplete={updateChore}
                  doOneOffChore={doOneOffChore}
                />
              </Route>
              <Route path="/dashboard">
                <UserDashboard
                  userActivity={dashboard.users}
                  onDelete={deleteChore}
                />
              </Route>
            </Switch>
          )}
        </Center>
      </div>
    </div>
  );
}
