import React from "react";
import UserSummary from "./UserSummary";

export default function UserDashboard({ userActivity, onDelete }) {
  if (userActivity.length === 0) {
    return null;
  }

  const { name } = userActivity.reduce((acc, curr) => {
    return acc.todayPoints > curr.todayPoints ? acc : curr;
  });

  return (
    <div>
      <h1>User Dashboard</h1>
      {userActivity.map((userData) => (
        <UserSummary
          key={userData.name}
          {...userData}
          championName={name}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
}
