import React from "react";

import { Box, Badge, Divider, List } from "@chakra-ui/react";

import { SunIcon } from "@chakra-ui/icons";

import { SummaryTask } from "./SummaryTask";

export default function UserSummary({
  name: userName,
  points,
  activity,
  todayPoints,
  championName,
  onDelete,
}) {
  const isChampion = userName === championName;
  return (
    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" mb="2">
      <Box p="1">
        <Box
          display="flex"
          alignItems="baseline"
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
        >
          <Badge borderRadius="full" px="2" colorScheme="teal" marginRight="2">
            {points}
          </Badge>
          <Badge
            borderRadius="full"
            px="2"
            colorScheme="purple"
            marginRight="2"
          >
            {todayPoints}
          </Badge>
          {userName}
          {isChampion && <SunIcon marginLeft={2} />}
        </Box>
        <Divider />
        <List spacing="1">
          {activity
            .filter((action) => action.name !== undefined)
            .map((action, i) => (
              <SummaryTask
                key={`${action.name}-${i}-${action.lastCompletedDate}`}
                action={action}
                index={i}
                onDelete={onDelete}
              />
            ))}
        </List>
      </Box>
    </Box>
  );
}
