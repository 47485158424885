import React from "react";
import { ListItem, IconButton } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { differenceInDays } from "date-fns";

export const SummaryTask = ({ action, index, onDelete }) => {
  const [isClicked, setIsClicked] = React.useState(false);
  const { name, lastCompletedDate } = action;

  const daysSinceLastCompleted = differenceInDays(
    new Date(),
    new Date(lastCompletedDate)
  );

  const color = daysSinceLastCompleted > 1 ? "teal.300" : "purple.300";

  return (
    <ListItem key={`${name}-${index}-${lastCompletedDate}`} fontSize="xs">
      <IconButton
        as={CheckCircleIcon}
        color={isClicked ? "pink.300" : color}
        size="xs"
        disabled={isClicked}
        onClick={() => {
          setIsClicked(!isClicked);
          onDelete(action);
        }}
        marginRight="2"
      />
      {name.toLowerCase()}
    </ListItem>
  );
};
