export const getUser = async (userDetails) => {
  //fetch post userDetails to api/login and execute the callback function onUserUpdate
  const data = await fetch("/api/login", {
    //fetch post userDetails to api/login
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userDetails),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  return data;
};
