import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUsersActivity, deleteUserActivity } from "./dashboardAPI";
import { differenceInDays } from "date-fns";

const initialState = {
  users: [],
  hasLoaded: false,
  isLoading: false,
  hasError: false,
  error: null,
};

export const fetchUsersActivity = createAsyncThunk(
  "user/fetchUsersActivity",
  async (details) => {
    const response = await getUsersActivity(details);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const fetchUserActivityUpdate = createAsyncThunk(
  "user/fetchUserActivityUpdate",
  async (details) => {
    const response = await deleteUserActivity(details);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const dashboardSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersActivity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsersActivity.fulfilled, (state, action) => {
        const userData = action.payload.map(tallyUserPointTotals);
        state.users = userData;
        state.hasLoaded = true;
        state.isLoading = false;
      })
      .addCase(fetchUserActivityUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserActivityUpdate.fulfilled, (state, action) => {
        console.log(action);
        const userData = action.payload.map(tallyUserPointTotals);
        state.users = userData;
        state.hasLoaded = true;
        state.isLoading = false;
      });
  },
});

function tallyUserPointTotals({ user, activity }) {
  //filter activity for lastCompletedDate within 7 days of today and total points
  const lastSevenDays = activity
    .filter(
      (activity) =>
        new Date(activity.lastCompletedDate).getTime() >
        new Date(new Date().setDate(new Date().getDate() - 7)).getTime()
    )
    .sort((a, b) => {
      return new Date(b.lastCompletedDate) - new Date(a.lastCompletedDate);
    });

  const todayActivity = activity
    .filter(
      (activity) =>
        differenceInDays(new Date(), new Date(activity.lastCompletedDate)) <= 1
    )
    .sort((a, b) => {
      return new Date(b.lastCompletedDate) - new Date(a.lastCompletedDate);
    });

  const todayPoints = todayActivity.reduce((acc, curr) => {
    return acc + curr.points;
  }, 0);

  const totalPoints = lastSevenDays.reduce((acc, curr) => {
    return acc + curr.points;
  }, 0);
  return {
    name: user,
    points: totalPoints,
    activity: lastSevenDays,
    todayPoints,
  };
}

export default dashboardSlice.reducer;
