// A mock function to mimic making an async request for data
const myka = {
  userName: "myka",
  userId: "b132271e-714e-446e-93da-853c647a90d9",
};

export const getChores = async (user) => {
  const { userId, userName } = user;
  const chores = await fetch("/api/chores", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userName, userId }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return chores;
};

export const completeChore = async (props) => {
  // console.log(props);
  const { userId, userName, choreName } = props;

  const data = await fetch("/api/completeChore", {
    //fetch post userDetails to api/login
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userName, userId, choreName }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  return data;
};

export const completeOneOffChore = async (props) => {
  console.log(props);
  const { userId, userName, choreName } = props;

  const data = await fetch("/api/completeOneOffChore", {
    //fetch post userDetails to api/login
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userName, userId, choreName }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  return data;
};

export async function fetchCount(amount = 1, user = myka) {
  const chores = await getChores(user);
  // console.log(chores);
  return { data: amount, chores };
}
